import React from 'react';

const QuisuisjeContent = () => {
    
    return (
        <div>
            <h1 className="title1">Qui suis-je?</h1>
            <p className="presentation">Originaire de Bayonne, je m'appelle Oihan et j'ai 20 ans. <br/>
            Actuellement étudiant en informatique, dynamique, organisé et entreprenant j'aime vivre à travers de
multiples projets.</p>
            <ul className="description">
                <li className="paragraphe">
                    <h2>Mon Parcours</h2>
                    <p>Né au Pays des Basques, j’ai grandi dans cette magnifique région avec une forte culture et de nombreuses valeurs. <br/><br/>
                    
                        Mon parcours scolaire s’est réalisé sans difficulté du primaire au secondaire. C’est durant ce dernier que
                        j’ai découvert l’informatique. À la recherche d’une éventuelle orientation post-bac, j’ai écouté les conseils
                        de mon père : suivre des tutoriels en ligne sur l’informatique et plus particulièrement la programmation
                        Web. J’ai commencé à suivre mes premiers tutoriels sur Openclassroom. Me sentant dans mon élément
                        et étant à l’aube de mon entrée en Terminale, j’ai tout naturellement choisi la spécialité Informatique et
                        Sciences du Numériques (ISN) pour compléter mon Bac Scientifique. Malgré un enseignement de deux
                        heures par semaines du langage Python, ma soif de l’informatique continua de grandir, j’ai alors décidé
                        d’orienter mes études supérieures dans ce domaine. <br/><br/>

                        Septembre 2019, après l’obtention de mon Baccalauréat, j’entame un Dut Informatique à Anglet. Au
                        cours de ces deux années d'enseignement, j’ai énormément appris de près ou de loin les différents
                        domaines de l’informatique comme la programmation, les Bases de données, l’algorithmie et le
                        développement d’application.
                    </p>
                </li>
                <li className="paragraphe">
                    <h2>Extrascolaire</h2>
                    <p>De 6 ans à 18 ans, j’ai pratiqué le football. Pendant 12 ans, j’ai joué à haut niveau dans les équipes de
                        jeunes, ce qui m’a permis de me construire un petit palmarès, mais aussi et surtout de développer
                        diverses qualités humaines et sociales. En effet, j’ai appris à travailler en équipe, à être solidaire et être
                        un meneur d’hommes.<br/><br/>

                        Le football m’a apporté un esprit combattif, m’a appris à ne rien lâcher, toujours se battre pour atteindre
                        ses objectifs, le fair-play et l’humilité. Ce sport m’a permis d’avoir des valeurs, des principes et d’être
                        discipliné. Toutes les qualités acquises peuvent être réappliquer dans la vie de tous les jours et c’est ce
                        que j’essaye de faire au maximum afin d’être une meilleure personne chaque jour.
                    </p>
                </li>
                <li className="paragraphe">
                    <h2>Mes passions</h2>
                    <p>La pratique du sport et du football a eu une place importante dans ma vie et malgré le fait que je ne
                        pratique plus le football en club, je continue d’avoir une hygiène sportive avec la pratique de nombreux
                        sports pour le plaisir comme la course à pied, la randonnée et le trail, les sports de glisses. Ces
                        pratiques me permettent de garder un équilibre avec ma vie étudiante et professionnel. <br/><br/>

                        Depuis quelques années j’ai développé une passion pour la production vidéo et depuis peu pour la
                        photographie. Lors de mes sorties sportives, je prends régulièrement une caméra embarquée afin de
                        filmer mes sessions et je réalise des petits montages. Aujourd’hui j’ai lancé ma <a className="chaine" href="https://www.youtube.com/channel/UCzw0IFbByXxZxCef3q31wKg">chaine</a> YouTube, j'y
                        partage mes vidéos de sessions sportives ou de mes découvertes de paysages lors de différents
                        voyages et escapades. Ces passions pour la vidéo et la photographie me permettent de développer un
                        esprit créatif mais critique. 
                    </p>
                </li>
            </ul>
            <p className="conclusion">Aujourd'hui, je suis en Licence Professionnelle Métiers du Numérique à l'IUT de Bayonne et du Pays Basque.<br/>
                Une fois ce diplôme obtenu, j'aspirerai au métier de développeur web. 
            </p>
        </div>
    );
};

export default QuisuisjeContent;