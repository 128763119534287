import React from 'react';
import Menubar from '../components/Menubar';
import Footer from '../components/Footer';
import ContactContent from '../components/ContactContent';

const contact = () => {
    return (
        <div className="contact">
            <div className="menu">
                <Menubar />
            </div>
            <div className="contactContent">
                <ContactContent />
            </div>
            <div className="foot">
                <Footer />
            </div>
        </div>
    );
};

export default contact;