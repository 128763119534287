import React from 'react';
import Menubar from '../components/Menubar';
import Footer from '../components/Footer';
import QuisuisjeContent from '../components/QuisuisjeContent';

const QuiSuisJe = () => {
    return (
        <div className="quiSuisJe">
            <div className="menu">
                <Menubar />
            </div>
            <div className="quiSuisJeContent">
                <QuisuisjeContent />
            </div>
            <div className="foot">
                <Footer />
            </div>
        </div>
    );
};

export default QuiSuisJe;