import React from 'react';

const MentionsLegalesContent = () => {
    return (
        <div>
            <h1>Mentions Légales</h1>

            <div className='mentions'>
                <h2>Propriétaire et éditeur du site</h2>
                <p>Oihan Maraud <br /> <br />
                    64100, Bayonne <br /> <br />
                    Tél. : +33 (0)7 82 12 11 90 <br /> <br />
                    Mail : oihanmaraud[arobase]gmail.com 
                </p>

                <h2>Réalisation du site</h2>
                <p>Développement technique : Oihan Maraud. <br /> <br />
                    Hébergement : 
                </p>

                <h2>Cookies</h2>
                <p>
                    Ce site n'utilise pas de cookies. 
                </p>

                <h2>Données personnelles</h2>
                <p>
                    Ce site n'utilise pas vos données personnelles. Les données récoltées via le formulaire de contact sont uniquement utilisées dans le cadre de votre demande.
                </p>
            </div>
        </div>
    );
};

export default MentionsLegalesContent;