import React from 'react';
import Menubar from '../components/Menubar';
import Footer from '../components/Footer';
import HomeContent from '../components/HomeContent';

const home = () => {
    return (
        <div className="home">
            <div className="menu">
                <Menubar />
            </div>
            <div className="homeContent">
                <HomeContent />
            </div>
            <div className="foot">
                <Footer />
            </div>
        </div>
    );
};

export default home;