import React from 'react';

const PortfolioContent = () => {
    return (
        <div>
            <h1>Portfolio</h1>
        
            <p>Cette page est encore en cours de construction. Veuillez revenir plus tard pour consulter les diverses projets sur lesquels j'ai travaillé.</p>
        </div>
    );
};

export default PortfolioContent;