import React from 'react';
import ReactPlayer from 'react-player';

const HomeContent = () => {
    
    const internsource = 'media/videoOfMe.mp4';
    return (
        <div className="player-wrapper">
            <ReactPlayer 
            url={internsource}
            playing
            muted
            loop
            width="100%"
            height="100%"
            className="player-video" />
        </div>
    );
};

export default HomeContent;
