import React, { useState } from 'react';
import { Link } from 'react-router-dom';


const ContactContent = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const isEmail = () => {
    let mail = document.getElementById('not-mail')
    let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  
    if (email.match(regex)){
      mail.style.display = 'none';
      return true;
    }
    else{
      mail.style.display = 'block';
      mail.style.animation = 'dongle 1s'
      setTimeout(() => {
        mail.style.animation = 'none'
      }, 1000)
      return false
    }
  }

  const isChecked = () => {
    let checkbox = document.getElementById('verif_rgpd').checked
    if (checkbox){
      return true
    }
    else{
      return false
    }
    
  }

  const failMessage = () => {
    let formMessage = document.querySelector('.form-message')

    formMessage.innerHTML = "Merci de remplir correctement les champs requis * ou d'accepter la politique de confidentialité"
    formMessage.style.opacity = '1';
    formMessage.style.background = 'rgb(253,87,87)';

    document.getElementById('name').classList.add('error');
    document.getElementById('email').classList.add('error');
    document.getElementById('message').classList.add('error');
    }

  const successMessage = () => {
    let formMessage = document.querySelector('.form-message')
    
    formMessage.innerHTML = 'Message envoyé ! Je vous recontacterez dès que possible.'
    formMessage.style.background ='#89f970 ';
    formMessage.style.opacity='1'

    document.getElementById('name').classList.remove('error');
    document.getElementById('email').classList.remove('error');
    document.getElementById('message').classList.remove('error');
    document.getElementById('verif_rgpd').checked = false;

    setTimeout(() => {
      formMessage.style.opacity ='0'
    }, 5000);
  }
  const handleSubmit = (e) => {
    e.preventDefault();

    if (isEmail() && name && message && isChecked()){
      sendFeedback("template_km1wl0r", {
        name,
        email,
        message,
      });
  }
  else {
    failMessage()
  }
  };

  const sendFeedback = (templateId, variables) => {

    window.emailjs
      .send("_oihan", templateId, variables)
      .then((res) => {
        successMessage();
        setName("");
        setEmail("");
        setMessage("");
      })
      .catch(
        (err) =>
          document.querySelector('.form-message').innerHTML =
            "Une erreur s'est produite, veuillez réessayer.")
  };

  return (
    <div>
      <h1>Pourquoi me contacter?</h1>
      <ul className="paragraphe">
        <li>
          <h3>Vous êtes un recruteur ou un chef d'entreprise </h3>
          Je suis actuellement à la recherche d'un stage dans le développement web du 30 Mai au 21 Septembre.<br />
          Ce stage peut déboucher sur un contrat de travail ou une alternance pour un éventuel Master dans le développement web. <br /><br />  
        </li>

        <li>
          <h3>Vous êtes un particulier, une association ou une entreprise</h3> 
          Si vous avez un projet de création de site Internet, de production vidéos ou un besoin de photographie, 
          vous pouvez me contacter afin de parler de votre projet et/ou me poser des questions. <br />
          Selon votre projet, je mettrais tout en oeuvre pour vous aider à trouver la solution la plus adaptée à vos besoins. <br /><br />
        </li>

        <li>
          <h3>Vous êtes étudiant et avez des questions</h3>
          Contactez-moi si vous avez des interrogations sur les diverses formations que j'ai réalisé,
          sur votre poursuite d'études ou sur différentes technologies pour vos projets.
        </li>
        </ul>

      <form action="" className="contact-form">
        <h2>Contactez Moi</h2>
        <div className="form-content">
          <input
            type="text"
            id="name"
            name="name"
            onChange={(e) => setName(e.target.value)}
            placeholder="Nom *"
            value={name}
            autoComplete="off"
          />
          <div className="email-content">
            <label id="not-mail">Email non valide</label>
            <input
              type="mail"
              id="email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email *"
              value={email}
              autoComplete="off"
            />
          </div>
          <textarea
            id="message"
            name="message"
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Message"
            value={message}
          />
          <p className='politique_confidentialite'>
            <input type="checkbox" id='verif_rgpd' className='verif_rgpd_checkbox'/> <span id="verif_rgpd_texte">J'accepte la <Link exact="true" to="/mentions-legales" className='mentionsLink'>politique de confidentialité</Link> du site et donc que les informations saisies soient utilisées dans le cadre de ma demande.</span>
          </p>
        </div>
        <input
          className="button"
          type="button"
          value="Envoyer"
          onClick={handleSubmit}
        />
        <div className="form-message"></div>
      </form>
    </div>
  );

};

export default ContactContent;