import React from 'react';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';

const Menubar = () => {

    const [showLinks, setShowLinks] = useState(false)

    const handleShowLinks = () =>{
        setShowLinks(!showLinks)
    }

    return (
        <nav className={`menubar ${showLinks ? "show-nav" : "hide_nav"} `}>
            
            <NavLink exact="true" to="/" className= "home_link" >
                <img src="./media/logoBlanc.png" alt="logo-pic"/>
            </NavLink>

            <ul className="navbar_links">
                <li className="navbar_item slideInDown1" >
                    <NavLink exact="true" to="/qui-suis-je" className={(nav) => (nav.isActive ? "nav-active" : "navbar_link")} >
                        Qui suis-je ?
                    </NavLink>
                </li>
                <li className="navbar_item slideInDown2">
                    <NavLink exact="true" to="/portfolio" className={(nav) => (nav.isActive ? "nav-active" : "navbar_link")} >
                        Portfolio
                    </NavLink>
                </li>
                <li className="navbar_item slideInDown3">
                    <NavLink exact="true" to="/contact" className={(nav) => (nav.isActive ? "nav-active" : "navbar_link")} >
                        Contact
                    </NavLink>
                </li>
            </ul>
            <button className="navbar_burger" onClick={handleShowLinks}>
                <span className="burger_bar"></span>
            </button>
        </nav>
        
    );
};

export default Menubar;