import React from 'react';

const NotFound = () => {
    return (
        <div>
            Cette page n'existe pas !
        </div>
    );
};

export default NotFound;