import React from 'react';
import Menubar from '../components/Menubar';
import Footer from '../components/Footer';
import PortfolioContent from '../components/PortfolioContent';

const portfolio = () => {
    return (
        <div className="portfolio">
            <div className="menu">
                <Menubar />
            </div>
            <div className="portfolioContent">
                <PortfolioContent />
            </div>
            <div className="foot">
                <Footer />
            </div>
        </div>
    );
};

export default portfolio;