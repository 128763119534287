import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './pages/Home'
import Contact from './pages/Contact'
import NotFound from './pages/NotFound'
import Portfolio from './pages/Portfolio'
import QuiSuisJe from './pages/QuiSuisJe'
import MentionsLegales from './pages/MentionsLegales'

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/qui-suis-je" element={<QuiSuisJe />} />
          <Route path="/mentions-legales" element={<MentionsLegales/>} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;