import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return(
        <footer className="footer">
            <p className="signature">From Scratch with React.js | <Link exact="true" to="/mentions-legales">Mentions Légales</Link></p>

            <ul className="socialNetworks">
                <li className="socialNetwork">
                    <a href="https://www.linkedin.com/in/oihan-maraud-816472207/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
                </li>
                <li className="socialNetwork">
                    <a href="https://github.com/oihan-maraud" target="_blank" rel="noopener noreferrer"><i className="fab fa-github"></i></a>
                </li>
                <li className="socialNetwork">
                    <a href="https://www.youtube.com/channel/UCzw0IFbByXxZxCef3q31wKg" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
                </li>
            </ul>
            
        </footer>
    );
};

export default Footer;