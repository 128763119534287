import React from 'react';
import Menubar from '../components/Menubar';
import Footer from '../components/Footer';
import MentionsLegalesContent from '../components/MentionsLegalesContent';

const MentionsLegales = () => {
    return (
        <div className="mentionsLegales">
            <div className="menu">
                <Menubar />
            </div>
            <div className="MentionsLegalesContent">
                <MentionsLegalesContent />
            </div>
            <div className="foot">
                <Footer />
            </div>
        </div>
    );
};

export default MentionsLegales;